@import './scss/variables';
@import './scss/mixins';

.AppWrapper {
  background-image: url('./images/background.png');
  background-color: #3318c7;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  font-family: $app-font;
  display: flex;
  justify-content: center;
  align-items: center;

  @include exclude-desktop() {
    align-items: flex-start;
  }
}

.AppWrapperGame {
  background-image: url('./images/background.png');

  @include exclude-desktop() {
    background-image: url('./images/mobile-game-background.jpg');
  }
}

.AppContainer {
  @include exclude-desktop() {
    width: 100%;
    margin: $app-container-mobile-margin-top;
  }
}

.Footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  @include exclude-desktop() {
    display: none;
  }
  .FooterText {
    color: white;
    width: 30rem;
    margin:0 2rem;
    text-align: center;
  }

  .FooterLogo1 {
    border-radius: 0.6rem;
    background-color: rgb(255, 255, 255);
    width: 14rem;
    height: 9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid pink;
    svg {
      width: 8.7rem;
    }
  }
  .FooterLogo2 {
   
    border: 1px solid pink;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    width: 14rem;
    height: 9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 8.5rem;
    }
  }
}

.AppWrapperRegister {
  @include exclude-desktop() {
    overflow-y: scroll;
  }
}
