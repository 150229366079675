@import '../../scss/variables';
@import '../../scss/mixins';
@import '../../scss/custom-classes';

.Register {
  :global form {
    @include desktop() {
      width: $register-desktop-width;
    }
  }

  .LoginHeader {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $register-container-margin-bottom;

    @include exclude-desktop() {
      margin-bottom: 0;
    }
  }

  .CardHeader .CardHeaderLogo svg {
    width: $mobile-logo-width;
    height: $mobile-logo-height;
  }

  .CustomInputGroup {
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin-bottom: $input-gap;
    }

    @include exclude-desktop() {
      flex-direction: column;
      margin-left: 0;
    }

    input {
      margin-right: $input-gap;

      @include exclude-desktop() {
        margin: 0;
        width: 100%;
      }
    }
  }

  .Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $register-footer-top;

    @include exclude-desktop() {
      width: 100%;
      flex-direction: column-reverse;
      text-align: center;
      align-items: center;
    }

    @extend .custom-link;
  }
}
