@import '../../scss/variables';
@import '../../scss/mixins';
.Contact {
  display: flex;
  margin-left: $contact-us-button-left;
  @include exclude-desktop() {
    margin-left: 0;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
  }
}

.HeaderContainer {
  width: 100%;
  padding: $questions-page-mobile-header-padding;
}

.ContactLogo svg {
  width: $mobile-logo-width;
  height: $mobile-logo-height;
}

.ContactModal {
  :global .MuiDialog-paperWidthSm {
    width: $contact-us-popup-width;
    height: $contact-us-popup-height;
    background: $contact-us-popup-background;
    border-radius: $contact-us-popup-radius;
    padding: $contact-us-popup-padding;
    max-width: $contact-us-max-width;
    overflow: hidden;
    @include exclude-desktop() {
      height: $contact-us-form-mobile-height;
      padding: $contact-us-popup-mobile-padding;
      width: 100%;
      margin: $contact-us-popup-mobile-margin !important;
      max-width: none;
    }
  }
  .ModalHeader {
    display: flex;
    justify-content: space-between;
    h2 {
      color: $contact-us-popup-title-color;
      font-size: $contact-us-popup-title-size;
      @include exclude-desktop() {
        font-size: $contact-us-popup-mobile-title-size;
        margin-bottom: 6.2rem;
      }
    }
    svg {
      cursor: pointer;
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .ModalBody {
    .ModalButtonnn {
      color: aqua !important;
    }
    .CustomInputGroup {
      display: flex;
      justify-content: space-between;
      margin-top: $contact-us-form-margin-top;
      :global .MuiFormControl-root {
        width: 100%;
      }
      input {
        width: 100%;
      }
      & > div {
        width: 100%;
        margin-right: $input-gap;
        @include exclude-desktop() {
          margin-bottom: $input-gap;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      @include exclude-desktop() {
        margin-top: $contact-us-form-mobile-margin-top;
        flex-direction: column;
      }
    }
  }
  .ModalFooter {
    > div {
      text-align: right;
      margin-top: $contact-us-buttons-margin-top;
    }
  }
}

.LogOutButton {
  margin-left: $log-out-margin-left;
  width: $log-out-width;
  height: $log-out-height;
  cursor: pointer;
  :global path {
    fill: $log-out-color;
    transition: all 0.3s;
  }
  &:hover {
    :global path {
      fill: $log-out-hover-color;
    }
  }
  &--position {
    position: absolute;
    right: 24px;
    top: 38px;
  }
}

.SuccessMassage {
  font-size: $success-msg-size;
  color: $success-msg-color;
  text-align: center;
}

.ContactLogo {
  display: none;
  @include exclude-desktop() {
    display: block;
    flex-grow: 1;
    svg {
      width: $mobile-logo-width;
      height: $mobile-logo-height;
    }
  }
}
