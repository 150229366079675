﻿$arm-font-reg: 'Arm-Regular', regular;
$arm-font-bol: 'Arm-Bold', bold;
$arm-font-it: 'Arm-Italic', italic;
$app-font: 'Noto-Sans-Armenia', sans-serif;

/*********************
COLORS
*********************/

$white: #fff;
$black: #000;
$dark-bg: rgba(25, 32, 94, 0.6);
$yellow: #ffb400;
$default-transition-second: 0.5s;
$red: #ff0000;
$green: rgb(0, 130, 0);
$red-lighten: #ff2c2c;
$blue-text: #20276f;
$purple: #6b12a9;
$gray: #6f685e;

$bg-input: #fff;
$bg-transparent: transparent;
$bg-card: rgba(25, 33, 94, 0.6);
$bg-cardItem: rgba(25, 33, 94, 0.8);
$next-btn: $yellow;
$next-btn-position: #ec7e00;
$border-select: #ffb400;
$bg-overlay: rgba(33, 28, 68, 0.9);
$btn-disabled: #74595b;
$validation-border: #ff0000;

/*********************
SHARED
*********************/
$desktop-width: 90rem;
$logo-height: 4.5rem;
$logo-width: 13rem;
$mobile-logo-width: 11rem;
$mobile-logo-height: 4rem;
$app-container-mobile-margin-top: 0.5rem 0;
$backdrop-background-color: rgba(0, 0, 0, 0.65);
$loading-logo-height: 6rem;
$loading-logo-width: 16rem;
$mui-select-svg-width: 2rem;
$mui-select-svg-height: 2rem;
$mui-select-input-padding: 7px 0 7px;

/*********************
POPUP
*********************/
$popup-background: #1e2453;

$popup-title-size: 3.6rem;
$popup-title-color: $white;
$success-msg-color: $white;
$success-msg-size: 1.2rem;

/*********************
LINKS
*********************/
$link-color: $yellow;
$link-secondary-color: $white;
$link-secondary-size: 1.4rem;
$link-size: 1.4rem;
$link-border: 1px solid $yellow;
$link-mobile-button-margin-bottom: 2.4rem;

/*********************
BREAKPOINTS
*********************/
$desktop: 991px;

/*********************
TYPOGRAPHY
*********************/
$title-size: 3.6rem;
$title-color: $white;
$title-margin-bottom: 2.5rem;
$title-font-weight: 900;
$title-margin-top: 5.5rem;
$title-size-mobile: 2.4rem;
$title-margin-bottom-mobile: 1.6rem;
$title-margin-top-mobile: 2.4rem;
$title-line-height: 1.35em;
$title-letter-spacing: 0.05em;

$paragraph-size: 1.4rem;
$paragraph-color: $white;
$paragraph-font-weight: normal;
$paragraph-line-height: 1.3em;

/*********************
MODAL_CIRCLE
*********************/
$modal-circle-stroke-width: 6px;
$modal-circle-lose-color: $red-lighten;
$modal-circle-rest-color: rgba(0, 0, 0, 0.3);
$modal-circle-remaining-color: $white;
$modal-circle-win-color: $yellow;

/*********************
BUTTON_LOADING
*********************/
$button-loading-width: 1.2rem;
$button-loading-height: 1.2rem;
$button-loading-border: 0.25em solid currentColor;
$button-loading-animation-second: 0.75s;
$button-loading-top: 50%;
$button-loading-left: 47%;
$button-loading-margin-top: -0.6rem;

/*********************
TOASTS
*********************/
$toast-font-size: 1.4rem;
$toast-font-weight: 500;
$toast-svg-height: 2.4rem;
$toast-svg-width: 2.4rem;

/*********************
LOG_OUT
*********************/

$log-out-width: 3.2rem;
$log-out-height: 3.2rem;
$log-out-color: $white;
$log-out-hover-color: #dbeaf0;
$log-out-margin-left: 1.6rem;

/*********************
HEADER
*********************/
$header-width: $desktop-width;
$header-margin-bottom: 2.7rem;
$header-second-logo-margin: 0 6rem;
$header-mobile-margin-top: 2rem;
$header-logo-third-width: 4rem;
$header-logo-second-width: 13rem;
$header-logo-first-width: 16.7rem;
$header-logo-height: 7rem;

/*********************
CARD
*********************/
$card-width: $desktop-width;
$card-bg: $dark-bg;
$card-height: 47rem;
$card-radius: 0.5em;
$card-mobile-width: 36rem;
$card-padding: 4.2rem 6.5rem;
$card-mobile-padding: 1.6rem 1.6rem 2.4rem 1.6rem;
$card-mobile-margin: 1.6rem auto 0.5rem auto;

/*********************
CUSTOM_INPUT
*********************/
$input-label-size: 1.6rem;
$input-label-color: $white;
$input-gap: 1.6rem;
$input-gap-login: 1.6rem;
$input-error-border: 1px solid $red;
$input-width: 23.8rem;
$input-height: 4.2rem;
$input-padding-left: 2.4rem;
$input-font-size: 1.4rem;
$input-margin-top: 0.8rem;
$input-margin-mobile-top: 0.4rem;

$error-color: $red;
$error-size: 1rem;
$error-width: 23rem;
$error-margin-top: 0.4rem;
$error-icon-right: 1.5rem;
$error-icon-top: 0.9rem;
$error-icon-mobile-top: 0.95rem;
$error-icon-textarea-top: 0.9rem;
$error-icon-width: 2.4rem;
$error-icon-height: 2.4rem;

/*********************
CUSTOM_BUTTON
*********************/
$custom-button-bg: $yellow;
$custom-button-font-size: 1.6rem;
$custom-button-line-height: 1.3em;
$custom-button-font-weight: bold;
$custom-button-color: $white;
$custom-button-radius: 0.5em;
$custom-button-height: 4.2rem;
$custom-button-width: 14.5rem;
$custom-button-mobile-font-size: 1.4rem;
$custom-button-mobile-height: 4rem;
$custom-button-mobile-width: 14rem;

/*********************
CONTACT-US
*********************/
$contact-us-width: 13.2rem;
$contact-us-max-width: 60rem;
$contact-us-height: 3.2rem;
$contact-us-border: 1px solid $white;
$contact-us-radius: 1.7em;
$contact-us-font: 1.1rem;
$contact-us-mobile-font: 1.1rem;
$contact-us-text-left: 2.5rem;
$contact-us-icon-width: 4.1rem;
$contact-us-icon-height: 3.2rem;
$contact-us-mobile-footer-top: 1.6rem;
$contact-us-mobile-padding: 0 1.6rem;

/*********************
CHOOSE_PERSONS
*********************/

$person-border: 1px solid $white;
$person-border-radius: 2.8%;
$person-width: 10rem;
$person-height: 13rem;
$person-scale-transition-second: $default-transition-second;
$person-img-height: 10.2rem;
$person-hover-transform: scale(1.1);
$next-button-margin-top: 10.4rem;
$next-button-padding-top: 1rem;

/*********************
SELECT_LANGUAGE
*********************/
$select-language-width: 15.2rem;
$select-language-height: 7.5rem;
$select-language-border: 1px solid $white;
$select-language-border-radius: 0.5em;
$select-language-margin-top: 3.8rem;
$select-language-text-size: 1.6rem;
$select-language-languages-padding-top: 0.7rem;
$select-language-color: $white;
$select-language-border: 1px solid $white;
$select-language-selected-border: 2px solid $yellow;
$select-language-selected-color: $yellow;
$select-language-text-transition: $default-transition-second;
$select-language-selected-top: 2px;
$select-language-text-margin-left: 1.6rem;
$select-language-svg-width: 3.4rem;
$select-language-mobile-svg-width: 2.8rem;
$select-language-svg-height: 2.6rem;
$select-language-margin-right: 2.4rem;
$select-language-mobile-margin-bottom: 1rem;
$select-language-mobile-item-width: 100%;
$select-language-mobile-item-height: 5.3rem;
$select-language-mobile-item-padding-left: 2rem;

/*********************
INFORMATION_BLOCK
*********************/
$information-block-margin-top: 5.2rem;
$information-block-width: calc(77.6rem - 6px);
$information-block-height: 13rem;
$information-block-border: 1px solid $yellow;
$information-block-mobile-height: 12rem;
$information-block-mobile-text-height: 9rem;
$information-block-mobile-margin-top: 1.5rem;
$information-block-mobile-padding: 1.5rem;

// Using px, because they are bug with %
$information-block-radius: 5px;
$information-block-padding: 1.5rem 1.9rem 1.2rem 1.6rem;
$information-block-text-margin-left: 1.25rem;
$information-block-text-max-width: 68.9rem;

/*********************
LANGUAGE_SELECT_BOX
*********************/
$language-select-width: 7.2rem;
$language-select-height: 3.2rem;
$language-select-color: $white;
$language-select-radius: 0.5em;
$language-select-border: 1px solid $white;
$language-select-option-size: 1.4rem;
$language-select-option-color: $white;
$language-select-option-left: 4px;
$language-select-icon-margin-left: 0.6rem;
$language-select-icon-margin-top: -0.4rem;
$language-select-icon-height: 1.6rem;
$language-select-icon-width: 1.5rem;
$language-select-arrow-width: 2.3rem;
$language-select-arrow-height: 2.6rem;
$language-select-option-text-left: 0.4rem;
$language-select-option-text-size: 1.2rem;

/*********************
COMMUNITY_SELECT_BOX
*********************/
$community-select-width: 23.8rem;
$community-select-height: 4.2rem;
$community-select-color: $white;
$community-select-radius: 0.5em;
$community-select-border: 1px solid $white;
$select-text-left: 1.4rem;
// $community-select-option-size: 1.4rem;
// $community-select-option-color: $white;
// $community-select-option-left: 4px;
// $community-select-icon-margin-left: 0.6rem;
// $community-select-icon-margin-top: -0.4rem;
// $community-select-icon-height: 1.6rem;
// $community-select-icon-width: 1.5rem;
// $community-select-arrow-width: 2.3rem;
// $community-select-arrow-height: 2.6rem;
// $community-select-option-text-left: 0.4rem;
// $community-select-option-text-size: 1.2rem;

/*********************
LOGIN
*********************/
$login-container-margin-top: 5.5rem;
$login-footer-top: 2.7rem;

/*********************
REGISTER
*********************/
$register-container-margin-bottom: 2.4rem;
$register-desktop-width: 79.2rem;
$register-footer-top: 0.8rem;

/*********************
HELPER_PERSON
*********************/
$helper-person-top: 10rem;
$helper-person-mobile-left: -2rem;
$helper-person-left: -3rem;
$helper-person-language-left: -15.5rem;
$helper-person-width: 14.3rem;
$helper-person-height: 32.6rem;
$helper-person-mobile-width: 15rem;
$helper-person-mobile-height: 30rem;
$helper-person-right: 8.5rem;
$helper-person-right-mobile: 1.5rem;
$helper-person-on-right-left: -21rem;
$chat-box-width: 38rem;
$chat-box-height: 15.2rem;
$chat-box-language-top: 2rem;
$chat-box-language-left: -3.2rem;
$chat-box-language-mobile-select-left: 5rem;
$chat-box-language-select-left: 0rem;
$chat-box-mobile-right-left: -13rem;
$chat-box-mobile-right-margin-top: 5.2rem;

$chat-box-top: -7.5rem;
$chat-box-margin-language-top: -7rem;
$chat-box-margin-top: 2rem;
$chat-box-mobile-margin-top: 6rem;
$chat-box-language-mobile-margin-top: -5rem;

$chat-box-right-margin-top: 5rem;
$chat-box-left: 8rem;
$chat-box-mobile-height: 12.2rem;
$chat-box-mobile-width: 30rem;
$chat-box-mobile-left: -3.5rem;
$chat-box-mobile-top: 0;

$chat-box-size: 1.8rem;
$chat-box-font-weight: 700;
$chat-box-text-margin: 0;
$chat-box-text-max-width: none;

/*********************
CONTACT_US
*********************/
$contact-us-button-left: 1.6rem;
$contact-us-popup-width: 80.5rem;
$contact-us-popup-height: auto;
$contact-us-popup-background: $popup-background;
$contact-us-popup-radius: 0.5em;
$contact-us-popup-padding: 3.2rem 4.2rem 3.2rem 4.2rem;
$contact-us-popup-title-size: $popup-title-size;
$contact-us-popup-mobile-title-size: 2.4rem;
$contact-us-popup-title-color: $popup-title-color;
$contact-us-form-margin-top: 6.2rem;
$contact-us-buttons-margin-top: 2.4rem;

$contact-us-popup-mobile-padding: 2.4rem 1.6rem 2.4rem 1.6rem;
$contact-us-form-mobile-margin-top: 1.6rem;
$contact-us-form-mobile-height: auto;
$contact-us-popup-mobile-margin: 0 1.6rem;

/*********************
CHOOSE_POSITION
*********************/
$choose-position-levels-margin-top: 2.4rem;
$choose-position-levels-border: 1px solid $white;
$choose-position-levels-padding: 1rem;
$choose-position-levels-height: 6rem;
$choose-position-levels-width: 28.2rem;
$choose-position-levels-color: $white;
$choose-position-levels-radius: 0.5em;
$choose-position-win-right: -3.9rem;
$choose-position-win-left: -1.7rem;
$choose-position-win-top: -1.4rem;
$choose-position-win-width: 3.7rem;
$choose-position-win-height: 3.7rem;

/*********************
CHOOSE_SECTION
*********************/
$choose-section-levels-margin-top: 2.4rem;
$choose-section-levels-border: 1px solid $white;
$choose-section-levels-padding: 1.9rem;
$choose-section-levels-padding-left: 2.4rem;
$choose-section-levels-padding-right: 1.3rem;

$choose-section-levels-height: 6rem;
$choose-section-levels-width: 24.8rem;
$choose-section-levels-color: $white;
$choose-section-levels-radius: 0.5em;
$choose-section-win-right: -3.9rem;
$choose-section-win-left: -1.7rem;
$choose-section-win-top: -1.4rem;
$choose-section-win-width: 3.7rem;
$choose-section-win-height: 3.7rem;

/*********************
CARD_ITEM
*********************/
$card-item-error-color: $red;
$card-item-selected-color: $green;
$card-item-font-size: 1.8rem;
$card-item-border: 2px solid $white;
$card-item-padding: 2.4rem;
$card-item-mobile-padding: 1.2rem;
$card-item-border-radius: 0.5em;
$card-item-color: $white;
$card-item-bg: darken($dark-bg, 10%);
$card-item-max-width: 75rem;

/*********************
QUESTIONS_PAGE
*********************/
$questions-page-question-margin-top: 1.6rem;
$questions-page-item-width: calc(50% - 1rem);
$questions-page-item-margin-top: 3.2rem;
$questions-page-title-margin-bottom: 2rem;
$questions-page-title-font-weight: 700;
$questions-page-title-font-size: 3.2rem;
$questions-page-title-color: $white;
$questions-page-title-top: 6.4rem;
$questions-page-width: 90rem;
$questions-page-mobile-padding: 0 1.6rem;
$questions-page-mobile-margin-top: 2rem;
$questions-page-mobile-header-padding: 0 1.6rem;
$questions-page-mobile-answer-height: 8rem;
$questions-page-answer-height: 10rem;

/*********************
MODAL_SAD
*********************/
$modal-sad-mobile-title-size: 2.4rem;
$modal-sad-max-width: 60rem;
$modal-sad-close-right: 2.4rem;
$modal-sad-mobile-close-right: 1.5rem;
$modal-sad-header-top: 2rem;
$modal-sad-header-mobile-top: 1.5rem;
$modal-sad-radius: 0.5em;
$modal-sad-bg: $popup-background;
$modal-sad-width: 55rem;
$modal-sad-mobile-width: 30rem;
$modal-sad-height: 38rem;
$modal-sad-mobile-height: auto;
$modal-sad-circle-height: 8rem;
$modal-sad-circle-percent-color: $white;
$modal-sad-person-height: 32rem;
$modal-sad-person-top: -5.8rem;
$modal-sad-person-left: -6.5rem;
$modal-sad-circle-percent-height: 4.2rem;
$modal-sad-circle-percent-width: 4.2rem;
$modal-sad-circle-percent-background: $red-lighten;
$modal-sad-circle-percent-size: 1.4rem;
$modal-sad-circle-percent-color: $white;
$modal-sad-title-color: $white;
$modal-sad-title-margin-top: 7rem;
$modal-sad-text-size: 1.8rem;
$modal-sad-text-margin-top: 0.8rem;
$modal-sad-title-padding-top: 1.6rem;
$modal-sad-title-size: 3.6rem;
$modal-sad-mobile-text-size: 1.4rem;
$modal-sad-mobile-text-width: 75%;
$modal-sad-text-max-width: 44.7rem;

$modal-sad-person-mobile-width: 4.7rem;
$modal-sad-person-mobile-top: -4.8rem;
$modal-sad-person-mobile-left: -3rem;

$modal-sad-stars-mobile-width: 20rem;

/*********************
MODAL_HAPPY
*********************/
$modal-happy-person-height: 32rem;
$modal-happy-person-left: -12rem;
$modal-happy-person-top: 0.5rem;
$modal-happy-circle-background: $yellow;
$modal-happy-circle-top: 4rem;
$modal-happy-stars-height: 12rem;
$modal-happy-stars-width: 28.7rem;
$modal-happy-person-mobile-width: 7.5rem;
$modal-happy-person-mobile-top: -5rem;

/*********************
CERTIFICATE
*********************/
$certificate-width: 97.4rem;
$certificate-mobile-width: 34.3rem;
$certificate-height: 66rem;
$certificate-mobile-height: 22.9rem;
$certificate-text-font-weight: 800;
$certificate-text-font-weight-normal: 400;
$certificate-text-wrapper-padding-left: 45.7rem;
$certificate-text-about-text: 7rem;
$certificate-text-title-font-size: 3.6rem;
$certificate-text-title-padding-top: 7.9rem;
$certificate-text-name-font-weight: 900;
$certificate-text-titlen-font-size: 2.4rem;
$certificate-text-margin-bottom-common-second: 2.4rem;
$certificate-text-margin-bottom-name: 1.6rem;
$certificate-text-padding-right: 5rem;
$certificate-text-font-size-common: 2.4rem;
$certificate-text-margin-bottom: 2.4rem;
$certificate-text-line-height-common: 1.22em;
$certificate-text-line-height-common-second: 1.36em;
$certificate-text-line-height-common-third: 1.5em;
$certificate-text-margin-bottom-common: 0.8rem;
$certificate-signature: 1px solid $purple;
$certificate-signature-width: 34.4rem;
$certificate-signature-margin-bottom: 1.6rem;
$certificate-text-date-font-size: 1.7rem;
$certificate-text-margin-bottom-data: 0.4rem;
$certificate-text-place-font-size: 1.7rem;
$certificate-text-place-margin-bottom: 3rem;
$certificate-text-mobile-margin-bottom-common-second: 0.8rem;
$certificate-text-titlen-mobile-font-size: 1rem;
$certificate-text-titlen-font-size: 2.4rem;
$certificate-text-mobile-line-height-common: 1.22em;
$certificate-text-mobile-margin-bottom: 0.18rem;
$certificate-text-mobile-padding-top: 2.2rem;
$certificate-text-mobile-font-size: 1.4rem;
$certificate-text-mobile-line-height: 1.36em;
$certificate-text-mobile-height: 22.9rem;
$certificate-mobile-width: 34.3rem;
$certificate-text-mobile-padding-left: 14.5rem;

/*********************
SLIDER
*********************/
$slider-width: 22.5rem;
$slider-img-width: 26rem;
$slider-margin: 3.6rem auto;
$slider-arrow-image: url(../../../images/svg/arrow.svg);
$slider-arrow-height: 1.5rem;
$slider-arrow-width: 1.2rem;
$slider-arrow-background: $yellow;
$slider-slides-color: $white;
$slider-slides-current-color: $yellow;
$slider-arrow-disabled-background: $gray;
$slider-arrow-padding: 0.7rem;
$slider-arrow-button-width: 3rem;
$slider-arrow-button-height: 3rem;
$slider-slides-current-font-size: 1.4rem;
$slider-slides-font-size: 1.2rem;
