@import '../../scss/variables';
@import '../../scss/mixins';

.HelperPerson {
  img {
    width: $helper-person-width;

    @include desktop() {
      height: $helper-person-height;
    }

    @include exclude-desktop() {
      width: $helper-person-mobile-width;
      height: $helper-person-mobile-height;
    }
  }

  .Overlay,
  .OverlayNone {
    background-color: $bg-overlay;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .Container {
    position: absolute;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  .Person {
    position: absolute;
    top: $helper-person-top;
    left: $helper-person-left;

    @include exclude-desktop() {
      left: $helper-person-mobile-left;
    }
  }

  :global .chat-box {
    position: relative;
    top: $chat-box-top;
    left: $chat-box-left;
  }

  .ChatText {
    position: absolute;
    top: 3rem;
    left: 2rem;

    background: url('../../images/svg/chat-box.svg');
    background-size: cover;
    margin-top: $chat-box-margin-top;
    width: $chat-box-width;
    min-height: $chat-box-height;
    background-position: center;
    display: flex;
    background-repeat: no-repeat;

    @include exclude-desktop() {
      margin-top: $chat-box-mobile-margin-top;
      top: $chat-box-mobile-top;
      left: $chat-box-mobile-left;
      width: $chat-box-mobile-width;
      min-height: $chat-box-mobile-height;
    }

    p {
      font-weight: $chat-box-font-weight;
      font-size: $chat-box-size;
      margin: $chat-box-text-margin;
      word-break: break-word;

      font-weight: 700;
      font-size: 1.8rem;
      margin: 0;
      word-break: break-word;
      margin: auto;
      padding-left: 6rem;
      width: 37rem;
      position: relative;
      top: -1rem;
      padding-right: 4rem;

      transition: all 1s;
    }

    @keyframes typing {
      from {
        width: 0;
      }
    }

    @keyframes blink {
      50% {
        border-color: transparent;
      }
    }
  }
}

// If person visible in the right position
.HelperPersonRight {
  .Person {
    right: $helper-person-right;
    left: initial !important;
    transform: rotateY(180deg);

    @include exclude-desktop() {
      right: $helper-person-right-mobile;
    }
  }

  :global .chat-box,
  :global img {
    transform: rotateY(180deg);
  }

  :global .chat-box {
    left: $helper-person-on-right-left;
  }

  .ChatText {
    transform: rotateY(180deg);
    padding: 1rem;
    // margin-top: $chat-box-right-margin-top;
    margin-top: 7rem;
    left: 1rem;
    background-image: url('../../images/svg/chat-box-right.svg');

    @include exclude-desktop() {
      margin-top: $chat-box-mobile-right-margin-top;
      left: $chat-box-mobile-right-left;
    }
  }
}

// If person showed selection for language, need corrections for showing person before languages
.HelperPersonSelectLanguage {
  .Person {
    top: calc(50% + 10rem);
    left: $helper-person-language-left;
  }

  :global .chat-box {
    top: $chat-box-language-top;
    left: $chat-box-language-left;
  }

  .ChatText {
    margin-top: $chat-box-margin-language-top;
    left: $chat-box-language-select-left;

    @include exclude-desktop() {
      left: $chat-box-language-mobile-select-left;
      margin-top: $chat-box-language-mobile-margin-top;
    }
  }
}

.ChatTextRight {
  right: 0;
}
