@import '../../scss/variables';
.CardItem {
  background-color: $card-item-bg;
  color: $card-item-color;
  border-radius: $card-item-border-radius;
  text-align: center;
  padding: $card-item-padding;
  border: $card-item-border;
  @media screen and (max-width: 991px) {
    width: 100%;
    height: auto;
    max-height: initial;
    padding: $card-item-mobile-padding;
  }
  p {
    word-break: break-word;
    font-size: $card-item-font-size;
    margin: auto;
    max-width: $card-item-max-width;
    @media screen and (max-width: 991px) {
      font-size: 1.6rem;
    }
  }
}

.CardItemRounded {
  border-radius: 3em;
}

.CardItemSelected {
  border: 2px solid $card-item-selected-color;
  color: $card-item-selected-color;
}

.CardItemError {
  border: 2px solid $card-item-error-color;
  color: $card-item-error-color;
}
