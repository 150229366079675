@import '../../../scss/variables';
@import '../../../scss/mixins';

.Title {
  font-size: $title-size;
  color: $title-color;
  margin-bottom: $title-margin-bottom;
  font-weight: $title-font-weight;
  line-height: $title-line-height;
  letter-spacing: $title-letter-spacing;

  @include exclude-desktop() {
    font-size: $title-size-mobile;
    margin-bottom: $title-margin-bottom-mobile;
    margin-top: $title-margin-top-mobile;
    text-align: center;
  }
}

.Paragraph {
  font-size: $paragraph-size;
  color: $paragraph-color;
  line-height: $paragraph-line-height;
  font-weight: $paragraph-font-weight;
  margin-bottom: 0;
}
