@import '../../scss/variables';
@import '../../scss/custom-classes';
.LoginHeader {
  display: flex;
  justify-content: flex-end;
}

.CardHeader .CardHeaderLogo svg {
  width: $mobile-logo-width;
  height: $mobile-logo-height;
}

.CustomInputGroup {
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-right: $input-gap-login;
    margin-bottom: 2rem;
    &:last-child {
      margin-right: 0;
    }
    @include exclude-desktop() {
      margin-right: 0;
      margin-bottom: $input-gap;
    }
  }
  @include exclude-desktop() {
    flex-direction: column;
  }
  input {
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}

.Footer {
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $login-footer-top;
  position: relative;
  @include exclude-desktop() {
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    margin-top: 0;
  }
  @extend .custom-link;
  .FooterText {
    color: white;
    max-width: 64%;
    @include exclude-desktop() {
      max-width: 100%;
    }
  }
}

.LoginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: $login-container-margin-top;
  @include exclude-desktop() {
    margin-top: 0;
  }
}

.LoginWrapper {
  @include exclude-desktop() {
    width: 100%;
  }
}

.CustomButton {
  position: relative;
  right: 3.2rem;
  @include exclude-desktop() {
    margin-top: 8px;
    margin-right: 0;
    top: 0;
    right: 0;
  }
}

.HelperPerson {
  div {
    div {
      div {
        div {
          @include desktop() {
            width: 65rem !important;
            height: 26.5rem;
            margin-top: -2rem !important;
            // background-image: url('../../images/svg/bigChatBoxBg.svg');
          }
          @include exclude-desktop() {
            width: 26.5rem !important;
            height: 23rem;
            background-image: url('../../images/svg/mobileChatBoxBg.svg') !important;
            margin-top: 3.2rem !important;
            left: -11rem !important;
          }
          p {
            font-size: 1.4rem !important;
            text-align: center;
            padding: 0;
            margin: 0 60px !important;
            top: 15% !important;
            width: 100% !important;
            @media screen and (max-width: 991px) {
              width: 26.5rem !important;
              padding: 0 !important;
              margin: 1.6rem !important;
              font-size: 1rem !important;
              top: 8% !important;
            }
          }
        }
      }
    }
  }
}
