@import '../../../scss/variables';
@import '../../../scss/mixins';

.Language {
  background-color: $bg-transparent;
  border: $select-language-border;
  width: $select-language-width;
  height: $select-language-height;
  border-radius: $select-language-border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include exclude-desktop() {
    justify-content: flex-start;
    cursor: pointer;
    padding-left: $select-language-mobile-item-padding-left;
  }

  svg {
    width: $select-language-svg-width;
    height: $select-language-svg-height;

    @include exclude-desktop() {
      width: $select-language-mobile-svg-width;
    }
  }

  span {
    font-size: $select-language-text-size;
    transition: $select-language-text-transition;
  }

  > div {
    &:nth-child(2) {
      margin-left: $select-language-text-margin-left;
    }
  }
}

.SelectedLanguage {
  position: relative;
  z-index: 5;
}
