@import '../../scss/variables';
@import '../../scss/mixins';

.ChooseSection {
  .CardHeader .CardHeaderLogo svg {
    width: $mobile-logo-width;
    height: $mobile-logo-height;
  }
  .SectionGroup {
    display: flex;
    margin-top: $choose-section-levels-margin-top;
    gap: 16px;
    justify-content: start;

    > div {
      position: relative;
      border: $choose-section-levels-margin-top;
      border-radius: $choose-section-levels-radius;
      color: $choose-section-levels-color;
      width: $choose-section-levels-width;
      height: $choose-section-levels-height;
      padding-left: $choose-section-levels-padding-left;
      padding-right: $choose-section-levels-padding-right;
      padding-top: $choose-section-levels-padding;
      padding-bottom: $choose-section-levels-padding;
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        font-size: 1.6rem;
        color: white;
        text-align: center;
        margin-bottom: 0;
      }

      @include exclude-desktop() {
        width: 100%;
        margin-bottom: 1.6rem;
      }

      // &:nth-child(2) {
      //   margin-left: 2.6rem;

      //   @include exclude-desktop() {
      //     width: 100%;
      //     margin: 1.6rem 0;
      //   }
      // }
    }

    @include exclude-desktop() {
      display: block;
    }

    .CheckMedal {
      display: block;

      svg {
        position: absolute;
        top: $choose-section-win-top;
        left: $choose-section-win-left;
        width: $choose-section-win-width;
        // height: $choose-position-win-height;
      }
    }
  }
}

.SectionGroupWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6.9rem;

  @include exclude-desktop() {
    margin-top: 0;

    & > div {
      width: 100%;
    }
  }
  .Title h1 {
    margin-bottom: 5.1rem;
  }
}

.CustomButtonAllow,
.CustomButton {
  text-align: right;
  // margin: auto;
  margin-top: 2.4rem;

  @include exclude-desktop() {
    width: 100%;
    text-align: center;
    margin-top: 1.6rem;
  }

  button,
  button:disabled:hover {
    background-color: $btn-disabled;

    @include exclude-desktop() {
      margin-top: 0;
      margin-top: 22.2rem;
    }
  }
}

.CustomButtonAllow {
  button {
    background-color: $next-btn-position;
  }
}

.CertificateLink {
  position: absolute;
  bottom: -3rem;
  text-align: left;
  width: 100%;
  font-size: 1.4rem;
  color: #ffb400;
  text-decoration: underline !important;

  &:hover {
    color: #fac64e;
  }

  svg {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.5rem;
  }
}

.CompletedGame {
  @include exclude-desktop() {
    margin-bottom: 4.5rem !important;
  }
}

.CustomButtonCompleted {
  @include exclude-desktop() {
    margin-top: 5rem !important;
  }
}
