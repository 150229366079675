@import './variables';
@import './mixins';

.custom-link {
  a {
    color: $link-color;
    margin-left: 0.5rem;
    border-bottom: $link-border;

    @include desktop() {
      font-size: $link-size;
    }
  }
  span {
    color: $link-color;
    margin-left: 0.5rem;
    border-bottom: $link-border;
    cursor: pointer;
    @include desktop() {
      font-size: $link-size;
    }
  }

  p {
    color: $link-secondary-color;
    font-size: $link-secondary-size;
    margin-bottom: 0;
  }

  button {
    @include exclude-desktop() {
      margin-bottom: 1.6rem;
      margin-top: 1.6rem;
    }
  }
}

.win-lose-popup {
  :global .MuiDialog-paperWidthSm {
    width: $modal-sad-width;
    // height: $modal-sad-height;
    background: $modal-sad-bg;
    border-radius: $modal-sad-radius;
    max-width: $modal-sad-max-width;
    height: auto;
    padding-bottom: 2rem;

    @include exclude-desktop() {
      width: $modal-sad-mobile-width;
      height: $modal-sad-mobile-height;
      padding-bottom: 1.5rem;
    }
  }

  .ModalHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: $modal-sad-header-top;

    @include exclude-desktop() {
      margin-top: $modal-sad-header-mobile-top;
      z-index: 1000000;
    }

    svg {
      margin-right: $modal-sad-close-right;
      cursor: pointer;

      @include exclude-desktop() {
        margin-right: $modal-sad-mobile-close-right;
      }
    }
  }

  .ModalBody {
    position: relative;

    .Stars {
      @include exclude-desktop() {
        width: $modal-sad-stars-mobile-width;
      }
    }

    .CircleGroup {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);

      > svg {
        &:first-child {
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          height: $modal-sad-circle-height;
        }
      }

      span {
        color: $modal-sad-circle-percent-color;
        position: absolute;
        font-size: $modal-sad-circle-percent-size;
        transform: translate(-45%);
        white-space: nowrap;

        background: $modal-sad-circle-percent-background;
        border-radius: 50%;
        height: $modal-sad-circle-percent-width;
        display: flex;
        align-items: center;
        width: $modal-sad-circle-percent-height;
        justify-content: center;
        top: 1.9rem;
        left: -0.2rem;
      }
    }

    .Title {
      color: $white;
      text-align: center;

      h2 {
        margin-top: $modal-sad-title-margin-top;
        font-size: $modal-sad-title-size;
        padding-top: $modal-sad-title-padding-top;

        @include exclude-desktop() {
          font-size: $modal-sad-mobile-title-size;
        }
      }

      p {
        margin: auto;
        margin-top: $modal-sad-text-margin-top;
        font-size: $modal-sad-text-size;
        width: $modal-sad-text-max-width;
        margin-bottom: 2.4rem;

        @include exclude-desktop() {
          font-size: $modal-sad-mobile-text-size;
          width: $modal-sad-mobile-text-width;
          margin-bottom: 0;
        }
      }
    }

    .SadPerson {
      position: absolute;
      left: $modal-sad-person-left;
      top: $modal-sad-person-top;
      height: $modal-sad-person-height;
    }

    .SadPerson,
    .HappyPerson {
      @include exclude-desktop() {
        left: $modal-sad-person-mobile-left;
      }
    }

    .HappyPerson {
      @include exclude-desktop() {
        top: $modal-happy-person-mobile-top;
        width: $modal-happy-person-mobile-width;
      }
    }

    .SadPerson {
      @include exclude-desktop() {
        top: $modal-sad-person-mobile-top;
        width: $modal-sad-person-mobile-width;
      }
    }
  }

  :global .MuiDialog-paper {
    overflow: inherit;
  }
}