@import '../../scss/variables';
@import '../../scss/mixins';
.SelectLanguageSection {
  .AboutusButton {
    position: absolute;
    right: 6.5rem;
    top: 9.2rem;
    width: 13.2rem;
    height: 3.2rem;
    border: 1px solid white;
    color: white;
    background-color: transparent;
    border-radius: 1.7em;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 13px;
    }
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 991px) {
      top: 2.2rem;
      right: 1.6rem;
      span {
        font-size: clamp(13px, 3vw, 20px);
      }
    }
  }
  .ContactSection {
    justify-content: flex-end;
    display: flex;
  }
  .LanguageSection {
    margin-top: $select-language-margin-top;
    @include exclude-desktop() {
      margin-left: 0;
    }
    .Languages {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      color: $select-language-color;
      padding-top: $select-language-languages-padding-top;
      @include exclude-desktop() {
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding-top: 0;
      }
      button {
        @include exclude-desktop() {
          margin-left: 0;
          margin-top: 1.5rem;
        }
      }
    }
  }
}

.SelectedLanguage {
  color: $select-language-selected-color;
  border: $select-language-selected-border;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.25));
  span {
    display: block;
    position: relative;
    top: $select-language-selected-top;
  }
}

.Language {
  color: $select-language-color;
  border: $select-language-border;
}

.NextButtonRight {
  position: relative;
  z-index: 5;
}

.LanguagesContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .SelectedLanguage,
  .Language {
    margin-right: $select-language-margin-right;
    @include exclude-desktop() {
      margin-bottom: $select-language-mobile-margin-bottom;
      width: $select-language-mobile-item-width;
      height: $select-language-mobile-item-height;
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

.Person {
  img {
    transform: rotateY(180deg);
  }
}

.AboutUsModal {
  width: 400px;
  height: 400px;
  background-color: rgb(85, 81, 81);
}

.AboutusHeader {
  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
  }
  padding: 10px;
}

.DialogContainer {
  background-color: #1e2453;
  color: white;
  padding: 10px;
}

.Aboutus {
  font-size: 14px;
  padding: 10px;
  span {
    text-decoration: underline;
  }
}

.AboutusIcon {
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: 10px;
  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    fill: white;
  }
}

.Ngoc {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 2px;
  margin-top: 10px;
  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    fill: white;
  }
}
