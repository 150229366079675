@import '../../../scss/variables';
@import '../../../scss/mixins';

.CustomButton {
  button {
    position: relative;

    background-color: $custom-button-bg;
    width: $custom-button-width;
    height: $custom-button-height;
    color: $custom-button-color;
    border-radius: $custom-button-radius;
    font-weight: $custom-button-font-weight;
    line-height: $custom-button-line-height;
    font-size: $custom-button-font-size;
    border: 0;
    outline: none;
    vertical-align: text-bottom;

    @include exclude-desktop() {
      width: $custom-button-mobile-width;
      height: $custom-button-mobile-height;
      font-size: $custom-button-mobile-font-size;
    }

    &:hover {
      background-color: #ffc632;
    }
  }
}
