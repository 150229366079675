@import '../../../scss/variables';

.SliderContainer {
  width: $slider-width;
  margin: $slider-margin;

  img {
    margin: 0 auto;
    height: $slider-img-width;
  }

  :global .slick-prev,
  :global .slick-next {
    &:before {
      content: '';
      background-image: $slider-arrow-image;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      width: $slider-arrow-width;
      height: $slider-arrow-height;

      display: block;
      transform: rotate(180deg);
    }
  }

  :global .slick-next {
    transform: translate(0, -50%) rotate(180deg);
  }

  button,
  button:focus,
  button:active,
  button:hover {
    background-color: $slider-arrow-background;
    height: $slider-arrow-button-height;
    width: $slider-arrow-button-width;
    border-radius: 50%;
    display: flex;
    padding: $slider-arrow-padding;
    justify-content: center;

    &:disabled {
      background-color: $slider-arrow-disabled-background;
    }
  }
}

.SlideCount {
  color: $slider-slides-color;
  font-size: $slider-slides-font-size;
  position: absolute;
}

.CurrentSlide {
  margin-right: 0.2rem;
  display: inline-block;
  font-size: $slider-slides-current-font-size;
}

.CurrentSlideCount {
  color: $slider-slides-current-color;
}
