.white-circle {
  &__circle {
    fill: none;
    stroke: none;
  }

  &__red {
    stroke-width: 6px;
    stroke: red;
    fill: rgba(0, 0, 0, 0.3);
    transition: all 9s;
  }

  &__yellow {
    stroke-width: 6px;
    stroke: #ffb400;
    fill: rgba(0, 0, 0, 0.3);
    transition: all 9s;
  }

  &__remaining {
    stroke-width: 6px;
    transform: rotate(90deg);
    transform-origin: center;
    fill-rule: nonzero;
    stroke: rgb(255, 255, 255);
  }
}
