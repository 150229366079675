@import './variables';

/*********************
BREAKPOINTS
*********************/

@mixin exclude-desktop() {
  @media screen and (max-width: $desktop) {
    @content;
  }
}

@mixin desktop() {
  @media screen and (min-width: $desktop) {
    @content;
  }
}
