@import '../../../scss/variables';
@import '../../../scss/mixins';

.CustomInput {
  position: relative;
  margin-bottom: 4px;

  @include exclude-desktop() {
    margin-bottom: 0;
  }

  .ErrorIcon {
    position: absolute;
    right: $error-icon-right;
    top: $error-icon-top;
    width: $error-icon-width;
    height: $error-icon-height;

    /*@include desktop() {
        right: 3.1rem;
    }*/

    @include exclude-desktop() {
      top: $error-icon-mobile-top;
    }
  }

  .ValidationBorder input {
    border: $input-error-border;
  }

  label {
    color: $input-label-color;
    display: inherit;
    margin-bottom: 0;
    font-size: $input-label-size;

  }

  input {
    background: $bg-transparent;
    border: solid 1px white;
    border-radius: 5px;
    width: $input-width;
    height: $input-height;
    line-height: $input-height;
    padding: 0;
    padding-left: $input-padding-left;
    color: $white;
    font-size: $input-font-size;
    box-sizing: border-box;
    padding-right: 4rem;
    
    @media screen and (max-width: 991px) {
      width: 100%;
      margin: auto;

      height: 0rem;
      padding-top: 2.1rem;
      padding-bottom: 2.1rem;
    }

    &:before {
      border-bottom: 0 !important;
    }
  }

  @media screen and (max-width: 991px) {
    :global .MuiFormControl-root {
      width: 100%;
    }
  }

  #outlined-basic:before {
    border-bottom: none !important;
    margin-top: 24px;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none !important;
  }
}

.root {
  margin-top: $input-margin-top;
  position: relative;

  @include exclude-desktop() {
    margin-top: $input-margin-mobile-top;
  }
}
