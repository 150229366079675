@import '../../scss/variables';
@import '../../scss/mixins';

.Nav {
  @include exclude-desktop() {
    display: flex;
    justify-content: end;
  }

  display: none;
}

.QuestionsPage {
  width: $questions-page-width;
  margin: auto;

  @include exclude-desktop() {
    width: 100%;
    padding: $questions-page-mobile-padding;
  }

  .Question {
    color: $questions-page-title-color;
    margin-top: $questions-page-title-top;

    @include exclude-desktop() {
      margin-top: $questions-page-mobile-margin-top;
    }

    h2 {
      font-size: $questions-page-title-font-size;
      font-weight: $questions-page-title-font-weight;
      margin-bottom: $questions-page-title-margin-bottom;
    }
  }

  .QuestionsItem,
  .QuestionItemGroup {
    margin-top: $questions-page-item-margin-top;
  }

  .QuestionsItem > div {
    display: flex;
    min-height: $questions-page-answer-height;

    @include exclude-desktop() {
      min-height: $questions-page-mobile-answer-height;
    }
  }

  .QuestionItemGroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include exclude-desktop() {
      flex-direction: column;
    }

    > div {
      width: $questions-page-item-width;
      min-height: 72px;
      cursor: pointer;
      display: flex;

      @include exclude-desktop() {
        width: 100%;
        margin-top: $questions-page-question-margin-top;

        &:first-child,
        &:last-child {
          margin-top: 0;
        }
      }

      &:nth-child(3),
      &:nth-child(4) {
        margin-top: $questions-page-question-margin-top;
      }
    }
  }
}
