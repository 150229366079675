@import '../../../scss/variables';
@import '../../../scss/custom-classes';

.Modal {
  @extend .win-lose-popup;

  .Stars {
    width: $modal-happy-stars-width;
    height: $modal-happy-stars-height;
  }

  .CircleGroup {
    top: $modal-happy-circle-top !important;

    span {
      background: $modal-happy-circle-background !important;
    }
  }

  img {
    position: absolute;
    top: $modal-happy-person-top;
    left: $modal-happy-person-left;
    height: $modal-happy-person-height;
  }

  h2 {
    margin-top: 0 !important;
  }

  .ModalBody {
    @include exclude-desktop() {
      margin-top: -4rem;
    }
    button {
      font-size: 1.6rem;
      width: auto;
      padding: 9px 13px;
    }
  }
}
