@import '../../../scss/variables';
@import '../../../scss/mixins';

.CustomTextarea {
  width: 100%;
  margin-top: $input-gap;
  position: relative;

  :global .MuiInput-root {
    padding: 0;
  }

  @include exclude-desktop() {
    width: 100%;
    margin-top: 0;
  }

  .ErrorIcon {
    position: absolute;
    right: $error-icon-right;
    top: $error-icon-textarea-top;
    width: $error-icon-width;
    height: $error-icon-height;
  }

  .ValidationBorder {
    textarea {
      border: $input-error-border;
    }
  }

  :global .MuiFormControl-root {
    width: 100%;
  }

  label {
    color: $input-label-color;
    display: inherit;
    margin-bottom: 0;
    font-size: $input-label-size;
  }

  textarea {
    background: $bg-transparent;
    border: solid 1px white;
    border-radius: 5px;
    padding: 0;
    padding-left: $input-padding-left;
    color: $white;
    font-size: $input-font-size;
    line-height: 1.4em;

    box-sizing: border-box;
    height: 15rem !important;

    padding-top: 1.5rem;
    padding-right: 4rem;
    padding-bottom: 4rem;

    @media screen and (max-width: 991px) {
      height: 8rem !important;
    }
  }
}

.CustomTextareaContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.root {
  margin-top: $input-margin-top;
  position: relative;

  @include exclude-desktop() {
    margin-top: $input-margin-mobile-top;
  }
}
