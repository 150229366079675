@import '../../../scss/variables';
@import '../../../scss/mixins';

.InformationBlock {
  border: $information-block-border;
  display: flex;
  border-radius: $information-block-radius;
  margin-top: $information-block-margin-top;
  width: $information-block-width;
  height: $information-block-height;
  padding: $information-block-padding;

  @include exclude-desktop() {
    width: 100%;
    margin-top: $information-block-mobile-margin-top;
    height: $information-block-mobile-height;
    padding: $information-block-mobile-padding;
  }

  p {
    max-width: $information-block-text-max-width;
    margin-left: $information-block-text-margin-left;

    @include exclude-desktop() {
      height: $information-block-mobile-text-height;
      overflow-y: scroll;
    }
  }

  svg {
    width: 3rem;
    height: 3rem;
  }
}
