@import '../../scss/variables';
@import '../../scss/mixins';

.MobileContact {
  .CardHeader .CardHeaderLogo svg {
    width: $mobile-logo-width;
    height: $mobile-logo-height;
  }

  .CustomInputGroup {
    & > div {
      margin-bottom: $input-gap;
      margin-right: 30px;
    }
  }

  .Title {
    position: relative;

    button {
      border: none;
      background: none;
      padding: 0;
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }

  .successMassage {
    font-size: 12px;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  .goBackMobile {
    outline: none;
  }
}

.ModalFooter {
  margin-top: $contact-us-mobile-footer-top;
}
