@import '../../scss/variables';
@import '../../scss/mixins';

.Header {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
  width: $header-width;
  margin: auto;
  margin-bottom: $header-margin-bottom;

  @include desktop() {
    position: relative;
    width: 100%;
  }

  @include exclude-desktop() {
    display: block;
    width: 100%;
    padding-top: 0;
    margin-bottom: 0;
    padding: $contact-us-mobile-padding;
  }

  > div {
    @include desktop() {
      justify-content: space-between;
      width: 100%;
    }

    > svg {
      height: $header-logo-height;

      &:nth-child(1) {
        width: $header-logo-first-width;
      }

      &:nth-child(2) {
        width: $header-logo-second-width;
        margin: $header-second-logo-margin;

        @include desktop() {
          position: absolute;
          left: 50%;
          transform: translate(-85%);
        }
      }

      &:nth-child(3) {
        width: $header-logo-third-width;
      }
    }
  }

  .LogosContainer {
    display: flex;
    justify-content: center;
    @include exclude-desktop() {
      justify-content: center;
    }

    .LogosContainerHeader {
      .first {
        width: 23.7rem;
        height: 11.2rem;
        @include exclude-desktop() {
          width: 12.1rem;
          height: 5.3rem;
        }
      }
      .second {
        width: 19.5rem;
        height: 11.2rem;
        margin-right: 4.4rem;
        @include exclude-desktop() {
          width: 9.4rem;
          height: 5.3rem;
        }
      }
      .third {
        width: 5.3rem;
        height: 11.2rem;
        @include exclude-desktop() {
          width: 2.7rem;
          height: 5.3rem;
        }
      }
      display: flex;
      justify-content: space-between;
      gap: 2.5rem;
      width: 100%;
      @include exclude-desktop() {
        width: 100%;
        justify-content: space-around;
      }
      div > svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.AppLogo {
  svg {
    width: $logo-width;
    height: $logo-height;
  }
}
