.TitleSide {
  font-size: 1.8rem;
  font-weight: bold;
}
.ButtonHamburger {
  padding: 0 !important;
  outline: none !important;
  min-width: auto !important;

  :global .MuiButton-label {
    justify-content: flex-end;
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.LanguageSelect {
  width: 28.2rem;
  border: solid 1px #e4e4e4;
  border-radius: 5px;

  svg {
    fill: #000;
    width: 2.2rem !important;
    height: 1.6rem !important;
  }

  span {
    color: black;
    margin-left: 1rem !important;
  }

  :global .logout-icon {
    margin-bottom: 6px;
  }

  :global .MuiFormControl-root {
    width: 100%;
  }

  :global .MuiMenuItem-root {
    margin-right: 1.5rem;
  }
}
a {
  text-decoration: none !important;
}

.CustomSideNavWrapper {
  width: 31.4rem;

  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
  .ul.MuiList-root.ml-3.logoutWrapper.MuiList-padding {
    display: flex !important;
    align-items: center !important;
  }
}
.ul.MuiList-root.ml-3.logoutWrapper.MuiList-padding {
  display: flex !important;
  align-items: center !important;
}
