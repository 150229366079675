@font-face {
  font-family: 'Noto-Sans-Armenia';
  src: url('../fonts/NotoSansArmenian-Regular.ttf');
}
@font-face {
  font-family: 'Arm-Bold';
  src: url('../fonts/GHEAGpalatBld.ttf');
}
@font-face {
  font-family: 'Arm-Regular';
  src: url('../fonts/GHEAGrpalatReg.ttf');
}
@font-face {
  font-family: 'Arm-Italic';
  src: url('../fonts/GHEAGrapalatBlit.ttf');
}
