@import '../../scss/variables';
@import '../../scss/mixins';

.Card {
  background-color: $card-bg;
  position: relative;
  width: $card-width;
  min-height: $card-height;
  margin: auto;
  border-radius: $card-radius;
  padding: $card-padding;

  @include exclude-desktop() {
    padding: $card-mobile-padding;
    width: $card-mobile-width;
    height: auto;
    margin: $card-mobile-margin;
  }
}
