﻿@-webkit-keyframes backInUp {
    0% {
        -webkit-transform: translateY(1200px) scale(0.7);
        transform: translateY(1200px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateY(0px) scale(0.7);
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes backInUp {
    0% {
        -webkit-transform: translateY(1200px) scale(0.7);
        transform: translateY(1200px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateY(0px) scale(0.7);
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

.animate__backInUp {
    -webkit-animation-name: backInUp;
    animation-name: backInUp;
    animation-duration:1.5s;
}


@-webkit-keyframes backInDown {
    0% {
        -webkit-transform: translateY(-1200px) scale(0.7);
        transform: translateY(-1200px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateY(0px) scale(0.7);
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes backInDown {
    0% {
        -webkit-transform: translateY(-1200px) scale(0.7);
        transform: translateY(-1200px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        -webkit-transform: translateY(0px) scale(0.7);
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

.animate__backInDown {
    -webkit-animation-name: backInDown;
    animation-name: backInDown;
    animation-duration:1.5s;
}