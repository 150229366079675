@import '../../scss/variables';
@import '../../scss/mixins';
.certificationPage {
  width: $questions-page-width;
  margin: auto;

  @include exclude-desktop() {
    width: 100%;
  }
}
.card {
  background-color: transparent;
  padding: 0;
}
.CertificateWrapper {
  width: $certificate-width;
  height: $certificate-height;
  background-color: $white;
  background-image: url('../../images/svg/certificate_BG.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;

  @include exclude-desktop() {
    width: 32.3rem;
    height: 22.9rem;
    background-image: url('../../images/svg/certificate_BG.png');
  }
}

.certificateTitle {
  text-align: center;
  font-size: 2rem;
  color: $white;
  margin: 2rem;
}
.certificateTextWrapper {
  padding-left: $certificate-text-wrapper-padding-left;

  height: 100%;
  @include exclude-desktop() {
    padding-left: 15.5rem;
    height: 23.1rem;
  }
  .title {
    line-height: $certificate-text-line-height-common-second;
    // font-size: $certificate-text-title-font-size;
    font-size: 5.6rem;
    font-family: $arm-font-bol;
    color: $blue-text;
    padding-top: $certificate-text-title-padding-top;
    margin-bottom: $certificate-text-margin-bottom-common;
    font-weight: 800;

    @include exclude-desktop() {
      line-height: 1.36em;
      font-size: 1.4rem;
      padding-top: 1.2rem;
      margin-bottom: 0.18rem;
    }
  }
  .smallTitle {
    line-height: $certificate-text-line-height-common-second;
    font-size: $certificate-text-title-font-size; //2.4rem
    color: $blue-text;
    font-family: $arm-font-bol;
    padding-top: $certificate-text-title-padding-top;
    margin-bottom: $certificate-text-margin-bottom-common;
    font-weight: $certificate-text-font-weight;
    @include exclude-desktop() {
      line-height: 1.36em;
      font-size: 1.4rem;
      padding-top: 1.2rem;
      margin-bottom: 0.18rem;
    }
  }
  .titleEn {
    line-height: $certificate-text-line-height-common;
    font-size: $certificate-text-titlen-font-size;
    color: $blue-text;
    margin-bottom: $certificate-text-margin-bottom-common-second;
    font-weight: $certificate-text-font-weight;
    @include exclude-desktop() {
      line-height: 1.22em;
      font-size: 0.85rem;
      margin-bottom: 0.8rem;
    }
  }
  .presented {
    line-height: $certificate-text-line-height-common;
    font-size: 2.4rem;
    font-family: $arm-font-reg;
    color: $blue-text;
    margin-bottom: $certificate-text-margin-bottom-common;
    font-weight: $certificate-text-font-weight-normal;
    @include exclude-desktop() {
      line-height: 1.36em;
      font-size: 0.7rem;
      margin-bottom: 0.375rem;
    }
  }
  .name {
    line-height: $certificate-text-line-height-common-second;
    font-size: 2.5rem;
    color: $yellow;
    font-family: $arm-font-bol;
    margin-bottom: $certificate-text-margin-bottom-name;
    font-weight: $certificate-text-name-font-weight;
    @include exclude-desktop() {
      line-height: 1.36em;
      font-size: 1.1rem;
      margin-bottom: 1.173rem;
    }
  }
  .smallName {
    line-height: $certificate-text-line-height-common-second;
    font-size: 2.5rem;
    color: $yellow;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: $certificate-text-name-font-weight;
    @include exclude-desktop() {
      line-height: 1.36em;
      font-size: 0.8rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  .aboutText {
    margin-right: $certificate-text-about-text;
    font-family: $arm-font-bol;
    @include exclude-desktop() {
      margin-right: 2.6rem;
    }
    .about {
      line-height: $certificate-text-line-height-common-third;
      font-size: 1.6rem;
      color: $blue-text;
      margin-bottom: $certificate-text-margin-bottom-common;
      font-weight: 700;

      @include exclude-desktop() {
        line-height: 1.66em;
        font-size: 0.6rem;
        margin-bottom: 0.2rem;
      }
    }
    .aboutEn {
      line-height: $certificate-text-line-height-common-third;
      font-size: $certificate-text-font-size-common;
      color: $black;
      margin-bottom: $certificate-text-margin-bottom;
      font-weight: $certificate-text-font-weight-normal;
      padding-right: $certificate-text-padding-right;
      @include exclude-desktop() {
        line-height: 1.66em;
        font-size: 0.6rem;
        margin-bottom: 0.8rem;
        padding-right: 0rem;
      }
    }
  }

  .signatureWrapper {
    .signature {
      border-bottom: $certificate-signature;
      width: $certificate-signature-width;
      font-size: 0.9rem;
      // margin-bottom: $certificate-signature-margin-bottom;
      @include exclude-desktop() {
        width: 14.3rem;
        margin-bottom: 0.85rem;
      }
    }
    .date {
      line-height: $certificate-text-line-height-common-second;
      font-size: $certificate-text-date-font-size;
      margin-bottom: $certificate-text-margin-bottom-data;
      font-weight: 600;
      font-family: $arm-font-reg;
      color: $blue-text;
      margin-top: 3rem;
      @include exclude-desktop() {
        line-height: 1.36em;
        font-size: 0.6rem;
        margin-top: 0;
      }
    }
    .footerText {
      width: $certificate-signature-width;
      font-family: $arm-font-it;
      @include exclude-desktop() {
        width: 14.3rem;
        // line-height: 1.66em;
        font-size: 0.4rem;
      }
    }
    .place {
      line-height: $certificate-text-line-height-common-second;
      font-size: $certificate-text-place-font-size;
      margin-bottom: $certificate-text-place-margin-bottom;
      font-family: $arm-font-reg;
      font-weight: 600;
      color: $blue-text;
      @include exclude-desktop() {
        line-height: 1.36em;
        font-size: 0.6rem;
        margin-bottom: 1.6rem;
        margin-bottom: 0;
      }
    }
  }
  .certificationMedal {
    position: relative;
    bottom: 9.5rem;
    margin-left: 36.4rem;
    width: 9.9rem;
    height: 9.9rem;
    background-image: url('../../images/svg/medal_1.svg');
    background-repeat: no-repeat;
    background-size: contain;
    @include exclude-desktop() {
      bottom: 4.2rem;
      margin-left: 12.9rem;
      margin-right: 0.4rem;
      width: 3.2rem;
      height: 3.2rem;
    }
  }

  .certificationMedal2 {
    position: relative;
    bottom: 11.5rem;
    margin-left: 38.4rem;
    width: 8.935rem;
    height: 11.636rem;
    background-image: url('../../images/svg/medal_2.svg');
    background-repeat: no-repeat;
    background-size: contain;
    @include exclude-desktop() {
      bottom: 4rem;
      margin-left: 12.9rem;
      margin-right: 0.4rem;
      width: 4rem;
      height: 4rem;
    }
  }

  .smallCertificationMedal2 {
    position: relative;
    bottom: 9.5rem;
    margin-left: 37.4rem;
    width: 8.935rem;
    height: 10.636rem;
    background-image: url('../../images/svg/medal_2.svg');
    background-repeat: no-repeat;
    background-size: contain;
    @include exclude-desktop() {
      bottom: 4rem;
      margin-left: 12.9rem;
      margin-right: 0.4rem;
      width: 4rem;
      height: 4rem;
    }
  }
}

.buttonWrapper {
  width: $certificate-width;
  margin: auto;

  @include exclude-desktop() {
    width: 32.5rem;
  }
}
.nextButton {
  button {
    color: $yellow;
    background-color: transparent !important;
    border: 2px solid $yellow;
    transition: 0.2s;

    &:hover {
      color: $white;
      transition: 0.2s;
      border: 2px solid $yellow;
      background-color: $yellow !important;
    }
  }
}
