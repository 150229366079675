@import 'scss/animations';
@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/fonts';

html {
  font-size: 62.5%;
  box-sizing: border-box;

  @include exclude-desktop() {
    position: fixed;
  }
}

* {
  box-sizing: border-box;

  &::after,
  &::before {
    box-sizing: inherit;
  }
}

body {
  margin: 0;
  padding: 0;

  @include exclude-desktop() {
    position: fixed;
    width: 100%;
    height: 100%;

    .quiz-app {
      position: fixed;
      width: 100%;
      overflow: scroll;
    }
  }

  @media screen and (min-height: 736px) and (max-width: 500px) {
    overflow-y: hidden;

    .quiz-app {
      overflow: hidden;
    }
  }

  &.scroll-lock {
    @include exclude-desktop() {
      overflow-y: scroll;

      .AppWrapper {
        padding-bottom: 10rem;
      }

      .quiz-app {
        overflow: scroll;
      }
    }
  }
}

.quiz-app {
  min-height: 100vh;
  height: 100%;
  box-sizing: content-box;
}

.white-circle {
  &__circle {
    fill: none;
    stroke: none;
  }

  &__red {
    stroke-width: 6px;
    stroke: $red-lighten;
    fill: rgba(0, 0, 0, 0.3);
    transition: all 9s;
  }

  &__yellow {
    stroke-width: 6px;
    stroke: #ffb400;
    fill: rgba(0, 0, 0, 0.3);
    transition: all 9s;
  }

  &__remaining {
    stroke-width: 6px;
    transform: rotate(90deg);
    transform-origin: center;
    // transition: all 0.9s linear;
    fill-rule: nonzero;
    stroke: rgb(255, 255, 255);
    // -webkit-filter: drop-shadow(0 0 1.7px red);
    // filter: drop-shadow(0 0 1.7px red);
  }
}

.errorStyle {
  position: absolute;
  color: $error-color;
  font-size: $error-size;
  width: $error-width;
  margin-top: $error-margin-top;
  margin-bottom: 0;
}

input:-internal-autofill-selected {
  background-color: transparent;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fbfbfb inset;
}

// Btn loading indicator
.spinner-border {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
  position: absolute;
  margin-top: -0.6rem;
  left: 47%;
  top: 50%;
}

// Reset

.slick-slide,
.slick-slide * {
  outline: none !important;
}

.MuiBackdrop-root {
  background-color: $backdrop-background-color !important;
}

.ct-text {
  font-size: 1.4rem;
  font-weight: 500;

  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.MuiButtonBase-root.MuiListItem-root svg {
  width: 2rem;
  height: 2rem;
}

.MuiSelect-root.MuiInputBase-input {
  padding: 7px 0 7px;
}
