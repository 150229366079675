@import '../../../scss/variables';
@import '../../../scss/mixins';
:global {
  .MuiMenu-list {
    max-height: 34rem;
  }
}

.CustomSelect {
  width: $language-select-width;
  height: $language-select-height;
  border: $language-select-border;
  border-radius: $language-select-radius;
  color: $language-select-color;
  :global .MuiFormControl-root,
  :global .MuiInput-formControl {
    height: 100%;
    width: 100%;
  }
  :global .MuiSelect-select.MuiSelect-select {
    padding-right: 0;
  }
  .customSelectText {
    color: #fff;
  }
  span {
    color: $language-select-option-color;
    margin-left: $language-select-option-left;
    font-size: $language-select-option-size;
  }
  svg:not(:global .MuiSelect-icon) {
    margin-left: $language-select-icon-margin-left;
    margin-top: $language-select-icon-margin-top;
    width: $language-select-icon-width;
    height: $language-select-icon-height;
  }
  svg {
    color: $white;
  }
  :global .MuiSelect-icon {
    width: $language-select-arrow-width !important;
    height: $language-select-arrow-height !important;
    top: 0.6rem;
  }
  #outlined-basic:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none !important;
  }
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
}

.CustomSelectMenu {
  :global {
    .MuiListItem-root.Mui-disabled {
      display: none;
    }
    .MuiPaper-root {
      transform: translateY(5rem) !important;
    }
  }
}

.LanguageCustomSelectMenu {
  :global {
    .MuiPaper-root {
      transform: translateY(4.5rem) !important;
    }
  }
}

.CustomSelectCommunity {
  label {
    color: $input-label-color;
    display: inherit;
    margin-bottom: 0;
    font-size: $input-label-size;
  }
  .CustomSelectRoot {
    width: $community-select-width;
    height: $community-select-height;
    border: $community-select-border;
    border-radius: $community-select-radius;
    color: $community-select-color;
    margin-top: 0.8rem;
    @include exclude-desktop() {
      width: 100%;
    }
  }
  .ErrorBorder {
    border: 1px solid $red;
  }
  :global .MuiFormControl-root,
  :global .MuiInput-formControl {
    height: 100%;
    width: 100%;
  }
  svg:not(:global .MuiSelect-icon) {
    margin-left: $language-select-icon-margin-left;
    margin-top: $language-select-icon-margin-top;
    width: $language-select-icon-width;
    height: $language-select-icon-height;
  }
  svg {
    color: $white;
  }
  :global .MuiSelect-icon {
    width: $language-select-arrow-width !important;
    height: $language-select-arrow-height !important;
    top: 1.1rem;
  }
}

.SelectText {
  margin-left: $language-select-option-text-left;
  font-size: $language-select-option-text-size;
}

.SelectTextCommunity {
  margin-left: $select-text-left;
  font-size: $language-select-option-text-size;
  color: #fff;
}

.ErrorIcon {
  position: absolute;
  right: 1.5rem;
  top: 1.1rem;
  width: 2.4rem !important;
  height: 2.4rem !important;
  /*@include desktop() {
      right: 3.1rem;
  }*/
  @include exclude-desktop() {
    top: $error-icon-mobile-top;
  }
}

.Placeholder {
  color: #ffffff;
  opacity: 0.5;
  font-size: 1.4rem;
  padding-left: 2.4rem;
}

.SelectTextContainer {
  span {
    color: black;
  }
}
