@import '../../../scss/mixins';

.Progress {
  .progress {
    background-color: #6489ff !important;
  }

  :global .progress-bar {
    @include exclude-desktop() {
      max-width: 98%;
    }
  }
}
