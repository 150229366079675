@import '../../scss/variables';
@import '../../scss/mixins';
.ChoosePersons {
  min-width: 650px;
  @include exclude-desktop() {
    min-width: auto;
  }
  .Card {
    padding: 32px 24px 24px 24px;
    width: auto;
  }
  .PersonsContactLogo svg {
    width: $mobile-logo-width;
    height: $mobile-logo-height;
  }
  .PersonsContact {
    text-align: right;
    @include exclude-desktop() {
      text-align: center;
      margin-bottom: 2.7rem;
    }
    .HeaderLogoMobile svg {
      display: none;
      @include exclude-desktop() {
        display: block;
        width: 113px;
        height: 38px;
      }
    }
    .HeaderContainer {
      @include exclude-desktop() {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .TitleContainer {
    h1 {
      @include exclude-desktop() {
        font-size: 1.4rem;
      }
      font-size: 3.4rem;
    }
  }
  .Persons {
    .Title {
      margin-bottom: 8.9rem;
    }
    .Person {
      border: $person-border;
      text-align: center;
      border-radius: $person-border-radius;
      width: $person-width;
      height: $person-height;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      cursor: pointer;
      @include exclude-desktop() {
        margin-top: 1rem;
        align-items: inherit;
        width: 155px;
      }
      img {
        height: $person-img-height;
        transition: $person-scale-transition-second;
        @include exclude-desktop() {
          width: 100%;
          padding: 0.5rem;
        }
      }
      &:hover,
      &.PersonSelected {
        img {
          transform: $person-hover-transform;
        }
      }
      &.PersonSelected {
        border-color: $border-select;
      }
      &.PersonSelected img:first-child {
        display: none;
      }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        width: 145px;
      }
    }
    .Person img:last-child {
      display: none;
    }
  }
  .ChooseBtn {
    text-align: right;
    margin-top: $next-button-margin-top;
    @include exclude-desktop() {
      margin-top: 3.8rem;
    }
    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }
}
