@import '../../scss/variables';
@import '../../scss/mixins';

.LoadingIndicator {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -90%);
    overflow-y: hidden;


    @include desktop() {
        transform: translate(-50%, -50%);
        width: 23rem;
    }

    svg {
        width: $loading-logo-width;
        height: $loading-logo-height;
    }
}
